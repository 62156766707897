import PageProps from '@leuven2030/food/Page/PageProps';
import Page, { PageContext } from '@leuven2030/framework/Page/Page';
import 'twin.macro';
import React, { useContext } from 'react';
import NewsSection from '@leuven2030/food/News/NewsSection';
import { gql } from '@apollo/client';
import { EventsCalendarQueryContent } from '@leuven2030/food/queries/EventsCalendarQuery';
import { NewsQueryContent } from '@leuven2030/food/queries/NewsQuery';
import PartnerGrid from '@leuven2030/food/Partner/PartnerGrid';
import { FoundingPartnersQuery } from '@leuven2030/framework/Partner/PartnersQuery';
import LandingPageHero from '@leuven2030/food/LandingPage/LandingPageHero';
import MatchmakingIntro from '@leuven2030/food/Matchmaking/MatchmakingIntro';
import CalendarSection from '@leuven2030/food/Calendar/CalendarSection';
import PageLayoutHome from '@leuven2030/food/Page/PageLayoutHome';
import SectionIntro from '@leuven2030/food/Section/SectionIntro';

const Home = Page((props) => {
  const {
    prismic: { hero, ecofoodmap_intro }
  } = useContext(PageContext);
  return (
    <PageLayoutHome>
      <LandingPageHero {...hero} tw="font-bold" />
      <NewsSection tw="bg-white" />
      <CalendarSection />
      <PartnerGrid />
      <SectionIntro tw="bg-white" {...ecofoodmap_intro} />
    </PageLayoutHome>
  );
});

const HomePage = (props) => {
  return <Home {...props} />;
};

export const getStaticProps = PageProps({
  prismic: {
    query: gql`
      query {
          hero(uid:"homepage",lang:"nl-be"){
              text
          }
          calendar(uid:"calendar",lang:"nl-be"){
              title
              _meta{
                  uid
                  type
              }
          }
          allNews_articles(sortBy:published_date_DESC,lang:"nl-be") {
              totalCount
              edges {
                  node {
                      title
                      group{
                          ... on Update_group{
                              title
                              color
                              icon{
                                  ...ImageLink
                              }
                              _meta{
                                  uid
                              }
                          }
                                 
                      }
                      description
                      published_date
                      cover {
                          ...ImageLink
                      }
                      _meta {
                          uid
                          type
                      }
                      event_date_start
                      event_link
                      event_date_end
                      event_place
                      event_address
                  }
                  cursor
              }
          }
          news_page(uid: "default", lang: "nl-be") {
              title
              description
              read_more_label
              published_label
              join_event_label
              online_meeting_label
              subscribe_title
              subscribe_button_label
              _meta {
                  uid
                  type
              }
          }
          ${FoundingPartnersQuery}
          events: ${EventsCalendarQueryContent}
      }
    `
  }
});

export default HomePage;

//          news: ${NewsQueryContent}
