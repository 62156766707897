import 'twin.macro';
import React, { useContext, useState } from 'react';
import ImageURL from '@leuven2030/framework/Image/ImageURL';
import { PageContext } from '@leuven2030/framework/Page/Page';
import ImageBackground from '@leuven2030/framework/Image/ImageBackground';
import NavigationLink from '@leuven2030/framework/Navigation/NavigationLink';
import truncate from 'lodash/truncate';
import tw from 'twin.macro';

const PartnerCard = ({ cover, title, logo, _meta, ...props }) => {
  const [isHovering, setIsHovering] = useState(false);

  function getLogoUrl() {
    if (!logo) {
      return;
    }
    return ImageURL(logo.url, {
      w: 100,
      h: 100
    });
  }
  const {
    prismic: {
      layout: { read_more }
    }
  } = useContext(PageContext);

  return (
    <NavigationLink prismicMeta={_meta} {...props}>
      <div
        tw="overflow-x-hidden overflow-y-hidden relative rounded-full w-32 h-32 mx-auto"
        onMouseEnter={() => setIsHovering(true)}
        onMouseLeave={() => setIsHovering(false)}
      >
        {!!cover && (
          <ImageBackground
            src={cover.url}
            tw="rounded-full w-32 h-32 transform transition-transform duration-500 ease-in-out cursor-pointer"
            imgixParams={{
              width: 150,
              height: 150,
              fit: 'crop',
              //crop: 'faces',
              'mark-align': 'center,middle',
              'mark-pad': 250,
              mark64: getLogoUrl(),
              duotone: '222222,222222',
              'mark-fit': 'max',
              'duotone-alpha': 45
            }}
            css={[isHovering && tw`scale-105`]}
          />
        )}
      </div>
      <div tw="p-3 text-center">
        <div className="font-sohne" tw="hover:text-primary-400 font-medium">
          {truncate(title, { length: 15 })}
        </div>
      </div>
    </NavigationLink>
  );
};

export default PartnerCard;
