import 'twin.macro';
import React, { useContext } from 'react';
import { PageContext } from '@leuven2030/framework/Page/Page';
import { StyleContent } from '@leuven2030/framework/styles/general';
import PartnerCard from '@leuven2030/food/Partner/PartnerCard';
import NavigationLink from '@leuven2030/framework/Navigation/NavigationLink';
import Button from '@leuven2030/framework/Button/Button';

const PartnerGrid = () => {
  const {
    linkResolver,
    prismic: {
      layout: { read_more },
      partners_page: { founding_partners_title, _meta },
      allPartners: { edges }
    }
  } = useContext(PageContext);

  console.log(_meta);
  return (
    <div tw="bg-gray-100">
      <div tw="py-12 lg:py-16 space-y-12 flex flex-col " css={StyleContent}>
        <div tw="flex flex-col sm:flex-row items-center items-start sm:justify-between pb-10">
          <h2 tw="text-3xl text-black" style={{ lineHeight: 1.1 }}>
            {founding_partners_title}
          </h2>
          <NavigationLink prismicMeta={_meta}>
            <Button
              variant="contained"
              tw="mx-auto bg-primary-50 text-primary-400 border-transparent hover:bg-primary-900  hover:text-white mt-4 sm:mt-0"
            >
              {read_more}
            </Button>
          </NavigationLink>
        </div>
        <div tw="grid grid-cols-2 md:grid-cols-3 xl:grid-cols-6 lg:mt-8 gap-3 ">
          {edges.map(({ node }, index) => {
            return <PartnerCard key={index} {...node} />;
          })}
        </div>
      </div>
    </div>
  );
};
export default PartnerGrid;
