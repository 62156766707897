import 'twin.macro';

import { StyleContent } from '@leuven2030/framework/styles/general';
import React, { useContext } from 'react';
import { PageContext } from '@leuven2030/framework/Page/Page';
import NewsButton from '@leuven2030/framework/News/NewsButton';
import NewsCard from '@leuven2030/food/News/NewsCard';
import LandingPageTitle from '@leuven2030/food/LandingPage/LandingPageTitle';
import NewsSubscribe from '@leuven2030/food/News/NewsSubscribe';
import NavigationLink from '@leuven2030/framework/Navigation/NavigationLink';
import Button from '@leuven2030/framework/Button/Button';

const NewsSection = (props) => {
  const {
    prismic: {
      allNews_articles: { edges },
      news_page: { title, description, _meta },
      layout: { read_more }
    }
  } = useContext(PageContext);

  const filteredEdges = edges.slice(0, 3);
  return (
    <div tw="py-12" {...props}>
      <div css={StyleContent} tw="space-y-6">
        <div tw="space-y-3" {...props}>
          <div tw="w-full flex flex-col space-y-3 sm:( flex-row items-start justify-between space-x-3 space-y-0)">
            <LandingPageTitle title={title} />
            <div tw="flex justify-start md:justify-between">
              <NewsSubscribe
                tw="flex lg:mt-0 lg:justify-end pl-0 md:pl-2 pr-2"
                color="primary"
                buttonColor="primary"
              />
              <NavigationLink prismicMeta={_meta}>
                <Button
                  variant="contained"
                  tw="mx-auto bg-primary-50 text-primary-400 border-transparent hover:bg-primary-900  hover:text-white"
                >
                  {read_more}
                </Button>
              </NavigationLink>
            </div>
          </div>
        </div>
        <div tw="grid gap-12 md:grid-cols-2 xl:grid-cols-3" {...props}>
          {filteredEdges.map(({ node }) => (
            <NewsCard key={node._meta.uid} size="small" {...node} />
          ))}
        </div>
      </div>
    </div>
  );
};

export default NewsSection;
