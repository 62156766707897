import React from 'react';
import 'twin.macro';
import PrismicText from '@leuven2030/framework/Prismic/PrismicText';
import { css } from '@emotion/react';
import { StyleContent } from '@leuven2030/food/styles/general';

const StyleText = css`
  .prismic-text h1,
  .prismic-text h2 {
    text-transform: uppercase;
    line-height: 1.2;
  }
  .prismic-text h2 strong {
    color: white;
    font-weight: 400;
    font-family: SohneBreit;
  }
`;

const LandingPageHero = ({ text, ...props }) => {
  return (
    <div tw="bg-primary-300">
      <div
        tw="md:text-5xl md:text-5xl mx-auto py-12"
        css={[StyleText, StyleContent]}
        {...props}
      >
        <PrismicText text={text} />
      </div>
    </div>
  );
};

export default LandingPageHero;
