import CalendarList from '@leuven2030/food/Calendar/CalendarList';
import { StyleContent } from '@leuven2030/food/styles/general';
import React, { useContext } from 'react';
import 'twin.macro';
import { PageContext } from '@leuven2030/framework/Page/Page';
import Button from '@leuven2030/framework/Button/Button';
import NavigationLink from '@leuven2030/framework/Navigation/NavigationLink';

const CalendarSection = () => {
  const {
    prismic: {
      layout: { read_more },
      calendar: { title },
      news_page: { _meta }
    }
  } = useContext(PageContext);
  return (
    <div tw="mx-auto w-full py-12">
      <div css={StyleContent}>
        <div tw="flex flex-col sm:flex-row items-center items-start sm:justify-between pb-10">
          <h2 tw="text-3xl">{title}</h2>
          <NavigationLink
            prismicMeta={{
              type: 'calendar',
              uid: 'calendar'
            }}
          >
            <Button
              variant="contained"
              tw="mx-auto bg-primary-50 text-primary-400 border-transparent hover:bg-primary-900 hover:text-white mt-4 sm:mt-0"
            >
              {read_more}
            </Button>
          </NavigationLink>
        </div>
        <CalendarList />
      </div>
    </div>
  );
};

export default CalendarSection;
