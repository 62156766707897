import PageLayout from '@leuven2030/food/Page/PageLayout';
import 'twin.macro';
import React from 'react';
import NavigationProvider from '@leuven2030/framework/Navigation/NavigationProvider';
import { NavigationLinkModals } from '@leuven2030/framework/Navigation/NavigationLink';
import SubscriptionForm from '@leuven2030/framework/Subscription/SubscriptionForm';

const modals: NavigationLinkModals = [
  {
    uid: 'subscribe',
    Component: SubscriptionForm
  }
];
const PageLayoutHome = (props) => (
  <PageLayout variant="full">
    <NavigationProvider modals={modals}>
      <div tw="mx-auto w-full max-w-screen-xl">
        <div tw="z-20 relative px-0">
          <div tw="">
            <div
              tw="flex flex-col align-top bg-white rounded-sm overflow-hidden min-h-screen relative"
              {...props}
            ></div>
          </div>
        </div>
      </div>
    </NavigationProvider>
    {/*<Widget />*/}
  </PageLayout>
);

export default PageLayoutHome;
