import 'twin.macro';
import React from 'react';

const LandingPageTitle = ({ title, ...props }) => {
  return (
    <h2 tw="text-2xl leading-9 text-black sm:text-3xl sm:leading-10" {...props}>
      {title}
    </h2>
  );
};

export default LandingPageTitle;
